export function formatDate(date: string | Date) {
  const formatter = new Intl.DateTimeFormat("de-de", { dateStyle: "medium" });

  return formatter.format(new Date(date));
}

export function formatTime(date: string | Date) {
  const formatter = new Intl.DateTimeFormat("de-de", { timeStyle: "short" });

  return formatter.format(new Date(date));
}
