"use client";
import React, { useEffect } from "react";

declare global {
  interface Window {
    vinegar?: {
      ra: () => void;
    };
  }
}

function RefreshVinegar() {
  useEffect(() => {
    window?.vinegar?.ra();
  }, []);
  return null;
}

export default RefreshVinegar;
