function removeBaseUrl(url: string) {
  const u = url
    .replaceAll("https://innopartner.werkbank.de:4444", "")
    .replaceAll(process.env.BASE_URL ?? process.env.NEXT_PUBLIC_BASE_URL, "");
  if (u === "") return "/";
  return u;
}

function removeApiUrl(url: string) {
  const u = url.replaceAll(
    process.env.PLONE_API_URL ?? process.env.NEXT_PUBLIC_PLONE_API_URL,
    "",
  );
  if (u === "") return "/";
  return u;
}

function replaceBaseUrl(url: string) {
  return url
    .replaceAll(
      process.env.BASE_URL ?? process.env.NEXT_PUBLIC_BASE_URL,
      process.env.PLONE_API_URL ?? process.env.NEXT_PUBLIC_PLONE_API_URL,
    )
    .replaceAll(
      "https://innopartner.werkbank.de:4444",
      process.env.PLONE_API_URL ?? process.env.NEXT_PUBLIC_PLONE_API_URL,
    )
    .replaceAll(
      "https://0.0.0.0:3000",
      process.env.PLONE_API_URL ?? process.env.NEXT_PUBLIC_PLONE_API_URL,
    )
    .replaceAll(
      "https://localhost:3000",
      process.env.PLONE_API_URL ?? process.env.NEXT_PUBLIC_PLONE_API_URL,
    )
    .replaceAll(
      "http://localhost:3000",
      process.env.PLONE_API_URL ?? process.env.NEXT_PUBLIC_PLONE_API_URL,
    );
}

function addApiUrl(url: string) {
  return `${process.env.NEXT_PUBLIC_PLONE_API_URL ?? process.env.PLONE_API_URL
    }/${url}`;
}

function isPloneUrl(url: string) {
  return url.startsWith(process.env.BASE_URL);
}

const fileTypes = [".pdf", ".zip", ".pptx"];

function isFileUrl(url: string) {
  return fileTypes.some((fileType) => url.endsWith(fileType));
}

function isInternalUrl(url: string) {
  return url.includes("/intern");
}

function isInternalImage(url: string) {
  return url.match(/\/intern.*\/@@images\//);
}

export {
  removeBaseUrl,
  addApiUrl,
  removeApiUrl,
  replaceBaseUrl,
  isFileUrl,
  isPloneUrl,
  isInternalUrl,
  isInternalImage,
};
